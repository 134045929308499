<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-3-5banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">主数据管理系统</div>
            <div class="head-explainBox-content1">全球某医疗和实验室产品的制造商和分销商</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">为了使制药业现代化并与国际标准接轨，客户公司进行了重大的监管改革。按照GSP标准，在药品仓储，分
                销和物流领域，需要建立一套完整的数据治理系统，来应对定期/不定期的内部和外部审计。<br/>
                通过帮助用户标准化其业务规则、工作流程和审批流程，实现整个企业内各类主数据的治理。另外，通过面向服务的架构（SOA），提供了很强的适应
                性、易用性和快速部署的能力。</div>
        </div>
        <div class="container-1" style="display:flex;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
                <div class="purchase-content" style="width:24.8rem;">逐步实现企业的主数据管理“统一标准、集中管控、分级授权、专业管理”目标，实现主数据在研制两端融汇贯通
                    ，清晰规划各层面主数据应用规范和主数据管理职责，建立职责清晰、流程严密、长期且有效的主数据管理体系，在保证数据质量的前提下，实
                    现主数据在下游系统中的高效利用。</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5方便创建.svg"/>
                        <div>
                            <div class="title1">方便创建</div>
                            <div class="title2">轻松、方便地创建或修改主数据。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/05-3-5灵活管理.svg"/>
                        <div>
                            <div class="title1">灵活管理</div>
                            <div class="title2">在规范数据应用的前提下，又能灵活地管理工作流程。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5数据治理.svg"/>
                        <div>
                            <div class="title1">数据治理</div>
                            <div class="title2">通过数据清洗，校验规则实现数据治理。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-3-5可追溯.svg"/>
                        <div>
                            <div class="title1">可追溯可审计</div>
                            <div class="title2">任何主数据变更都会留下记录，实现可追溯和可审计。</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/05-3-5无缝对接.svg"/>
                        <div>
                            <div class="title1">无缝对接</div>
                            <div class="title2">无缝的上下游系统数据对接。</div>
                        </div>
                    </div>
                    <div style="width: 34.8rem;height: 15rem;">

                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 38.2rem;">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">完成GSP合规认证；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">提升流程的透明化，从而减少新增/变更主数据84%的申请时间；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">减少了人工干预，以及避免所带来的错误；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">为下游系统及时提供有效准确的数据，继而加快了业务决策；</div></div>
                <div class="purchase-title" style="display:flex;height: 5rem;"><div class="purchase-doit">●</div><div class="special-style">针对核心指标建立数据报表，为后续分析及流程完善打下良好的基础。</div></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'主数据管理系统,方便创建,灵活管理,数据治理,可追溯可审计,无缝对接' },
        { name:'description', content:'为了使制药业现代化并与国际标准接轨，客户公司进行了重大的监管改革。按照GSP标准，在药品仓储，分 销和物流领域，需要建立一套完整的数据治理系统，来应对定期/不定期的内部和外部审计。通过帮助用户标准化其业务规则、工作流程和审批流程，实现整个企业内各类主数据的治理。另外，通过面向服务的架构（SOA），提供了很强的适应 性、易用性和快速部署的能力' }
        ]
    },
    data() {
        return {
            
        };
    },

    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.special-style{
    height: 5rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #333333;
    margin-top: 0.2rem;
    line-height: 3rem;
}
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-doit{
    color: #f76f7c;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 107.2rem;
    height: 62.1rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 20rem;
    display: flex;
    .selected-card{
        width: 17.8rem;
        height: 20rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: justify;
            width: 14.6rem;
            height: 6rem;
            font-size: 16px;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #7A3C8F;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2.5rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.2rem 0 0 1.2rem;
        }
    }
}
</style>
